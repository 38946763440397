import { gql } from '@apollo/client'

// register_graphql_object_type('SaveWomenInBusiness', [
//     'fields' => [
//       'name' => [
//         'type' => 'String',
//       ],
//       'business_name' => [
//         'type' => 'String',
//       ],
//       'email' => [
//         'type' => 'String',
//       ],
//       'contact_number' => [
//         'type' => 'String',
//       ],
//       'website_url' => [
//         'type' => 'String',
//       ],
//       'business_address' => [
//         'type' => 'String',
//       ],
//       'have_uploaded_pdf' => [
//         'type' => 'Boolean',
//       ],
//       'have_confirmed_tide_account' => [
//         'type' => 'Boolean',
//       ],
//       'entryId' => [
//         'type' => 'ID',
//       ],
//       'businessType' => [
//         'type' => 'String',
//       ]
//     ]
//   ]);

export const SaveSchoolAwards = gql`
  mutation SaveSchoolAwards($payload: CreateSchoolAwardsPost!) {
    saveSchoolAwards(payload: $payload) {
      success
      entryid
    }
  }
`

export type ISchoolAwardsSave = {
  emailaddress?: string
  entryid?: string
  schoolid?: string
  name?: string
  jobtitle?: string
  county?: string
  town?: string
  telephone?: string
  schooltype?: string
  schoolname?: string
  postcode?: string
  awardcategory?: string
  additionalinformation?: string
  application?: string
  logo?: string
  schooldepartment?: string
  category?: string
  categoryname?: string
}
