import React, { ChangeEvent, useState } from 'react'
import * as FormStyles from '../FormStyles.module.scss'

interface FileUploadProps {
  awardType: string
  fileType: 'application' | 'logo'
  schooldepartment: string
  schoolname: string
  entryid?: string
  catname: string
  isUploaded: boolean
  fileUploaded: (fileName: string) => void
  catId: string
}

interface ErrorProps {
  state: boolean
  msg: string
}

const FileUpload = ({
  fileType,
  schoolname,
  catname,
  isUploaded,
  fileUploaded
}: FileUploadProps) => {
  const schoolName = schoolname
  const catName = catname
  const [loading, setLoading] = useState<boolean>(true)
  const [uploading, setUploading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorProps>({ state: false, msg: '' })
  const [selectedFile, setSelectedFile] = useState<File>()
  const [isFilePicked, setIsFilePicked] = useState<boolean>(false)
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(isUploaded)
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false)

  const isFileValid = (file: File) => {
    if (fileType === 'application' && file.type === 'application/pdf') {
      return true
    }
    const imgTypes = ['image/jpeg', 'image/png', 'image/gif']
    if (fileType === 'logo' && imgTypes.includes(file.type)) {
      return true
    }
    return false
  }

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      if (isFileValid(file)) {
        if (file.size > 6000000) {
          setError({
            state: true,
            msg: 'This file is too large. Please upload a file under 6mb.'
          })
          setReadyToUpload(false)
        } else {
          setSelectedFile(event.target.files[0])
          setIsFilePicked(true)
          setLoading(false)
          setError({ state: false, msg: '' })
          setIsFileLoaded(false)
          setUploading(false)
          setReadyToUpload(true)
        }
      } else {
        setError({ state: true, msg: "This file isn't valid" })
        setReadyToUpload(false)
      }
    } else {
      setIsFilePicked(false)
    }
  }

  // const circumventUpload = () => {
  //   fileUploaded('Circumvented Upload')
  // }

  const uploadFile = async (file: File) => {
    const newFileName = `${schoolName}_${file.name}`
    const fixedFile = new File([file], newFileName, { type: file.type })
    setUploading(true)

    try {
      const formData = new FormData()
      formData.append(
        'category',
        catName.replace(
          'The Debrett’s Education Award for Outstanding Pastoral Care',
          'Outstanding Pastoral Care'
        )
      )
      formData.append('file', fixedFile)

      const response = await fetch('/.netlify/functions/upload', {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()

      if (result.fileName) {
        setIsFileLoaded(true)
        setReadyToUpload(false)
        fileUploaded(result.fileName)
        setUploading(false)
      }
    } catch (error) {
      setUploading(false)
      setError({
        state: true,
        msg: `There was a problem uploading your file. ${error}`
      })
    }
  }

  const uploadToGoogle = () => {
    if (selectedFile) {
      uploadFile(selectedFile)
    }
  }

  const InputText = () => {
    if (uploading) {
      return <>Loading</>
    }
    if (isFileLoaded) {
      return <>File uploaded</>
    }
    return <>Upload file</>
  }

  return (
    <div className={FormStyles.Upload}>
      <p>
        {fileType === 'logo' && (
          <label htmlFor="fileurl">Upload your {fileType}</label>
        )}
        {fileType === 'application' && (
          <>
            <label htmlFor="fileurl">Submit your Entry Application</label>
            <span className={FormStyles.ApplicationFormPointer}>
              Haven&apos;t got the form yet? Download the{' '}
              <a
                href="/best-schools-awards/form/muddy-stilettos-best-schools-awards-application-2024.docx"
                target="_BLANK"
                rel="noreferrer"
              >
                entry form
              </a>{' '}
              here.
            </span>
          </>
        )}
        <span className={FormStyles.UploadFileField}>
          {isFileLoaded ? (
            <span style={{ flex: '1', color: 'rgb(231, 44, 94)' }}>
              File uploaded!
            </span>
          ) : (
            <input type="file" onChange={handleFileSelection} />
          )}
          <button
            type="button"
            onClick={uploadToGoogle}
            disabled={
              loading ||
              uploading ||
              !isFilePicked ||
              isFileLoaded ||
              !readyToUpload
            }
          >
            <InputText />
          </button>
        </span>
        {!isUploaded && isFileLoaded && (
          <small className={`${FormStyles.FileNote} ${FormStyles.UploadCTA}`}>
            <strong>Uploaded successfully!</strong>
          </small>
        )}
        {readyToUpload && !isFileLoaded && (
          <small className={`${FormStyles.FileNote} ${FormStyles.UploadCTA}`}>
            <strong>
              Click the Upload button to complete uploading this file.
            </strong>
          </small>
        )}
        {error.state && (
          <>
            <span className={FormStyles.ErrorMsg}>{error.msg}</span>
            {/* <small>
              If you&apos;re having issues uploading, please press the button
              below to proceed. You will need to email your file(s) directly to{' '}
              <a href="mailto:hq@muddystilettos.co.uk">our team</a>, with your
              school name and the category you wish to enter in the email body.
            </small>
            <div className="button-row has-two-buttons">
              <button
                type="button"
                className="button"
                onClick={() => circumventUpload()}
              >
                I will email this file
              </button>
            </div> */}
          </>
        )}
        {!isFileLoaded && (error.state || !readyToUpload) && (
          <>
            {fileType === 'logo' && (
              <small className={FormStyles.FileNote}>
                Only accepts GIFs, PNGs or JPGs. Max file size 6mb.
              </small>
            )}
            {fileType === 'application' && (
              <small className={FormStyles.FileNote}>
                Only accepts PDFs. Max file size 6mb.
              </small>
            )}
          </>
        )}
      </p>
    </div>
  )
}

export default FileUpload
