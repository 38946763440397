import React, { useState, useEffect } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { FormFields } from '../Form'
import { getPaymentIntent, makePayment } from '../../../../lib/utils'
import * as FormStyles from '../FormStyles.module.scss'
import { navigate } from 'gatsby'
import LoadingLogomark from '../../../../components/LoadingLogomark/LoadingLogomark'

interface Props {
  formData: FormFields
}

interface StripeResponse {
  client_secret: string
  payment_id: string
}

const PaidSubmission = ({ formData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const amount = '6500'
  const [paymentIntent, setPaymentIntent] = useState<StripeResponse>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [paid, setPaid] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [returnMessage, setReturnMessage] = useState<string>()

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    getPaymentIntent(amount).then((result: StripeResponse) => {
      setPaymentIntent(result)
      setLoading(true)
    })
  }, [])

  const handlePayment = async () => {
    if (!stripe || !elements || !paymentIntent) {
      return
    }
    setSubmitting(true)
    setErrorMessage('')
    const ele = elements.getElement(CardElement)
    if (!ele) {
      return
    }
    const stripeResult = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: {
          card: ele,
          billing_details: {
            name: `${formData.name}`,
            email: `${formData.emailaddress}`,
            address: {
              line1: `${formData.schoolname}`,
              line2: `${formData.categoryname}`,
              city: `${formData.town}`,
              postal_code: `${formData.postcode}`
            }
          }
        },
        receipt_email: `${formData.emailaddress}`
      }
    )

    if (stripeResult.error) {
      setSubmitting(false)
      setErrorMessage(
        'Sorry, there was a problem taking payment. ' +
          stripeResult.error.message
      )
    } else {
      if (stripeResult.paymentIntent.status === 'succeeded') {
        makePayment({
          entryId: `${formData.entryid}`,
          formData,
          amount: parseInt(amount, 10),
          paymentIntent: paymentIntent?.payment_id ?? '',
          paymentId: stripeResult.paymentIntent.id
        }).then(result => {
          const el = elements.getElement(CardElement)
          el?.clear()
          setSubmitting(false)
          if (result.success === 1) {
            setPaid(true)
            setSubmitted(true)
            setReturnMessage(
              'Thank you. Payment has now been taken and the entry has now been submitted.'
            )
          } else {
            setErrorMessage(result.message)
          }
        })
      }
    }
  }

  return (
    <>
      {!returnMessage && (
        <>
          <p className={FormStyles.SubmitCTA}>
            You are entering&nbsp;<strong>{formData.schoolname}</strong>
            &nbsp;into the&nbsp;
            <strong>{formData.categoryname}</strong>&nbsp;category. Once you
            submit your entry you will not be able to edit it.
          </p>
          <p>
            <strong>Please enter your payment details below:</strong>
          </p>
          <div style={{ width: '80%', margin: '24px auto 36px' }}>
            <CardElement options={{ hidePostalCode: true }} />
          </div>
          <p>
            <button
              type="button"
              disabled={
                returnMessage !== undefined ||
                submitting ||
                !stripe ||
                !loading ||
                submitted ||
                paid
              }
              onClick={() => handlePayment()}
            >
              {submitting && 'Submitting'}
              {!submitting && !returnMessage && <>Pay £65.00</>}
            </button>
          </p>
        </>
      )}

      {(submitting ||
        returnMessage !== undefined ||
        errorMessage !== undefined) && (
        <div style={{ textAlign: 'center' }}>
          <LoadingLogomark
            show={true}
            complete={
              !submitting &&
              (returnMessage !== undefined || errorMessage !== undefined)
                ? true
                : false
            }
            size="88px"
          />
          {submitting && <p>Submitting your entry. Please wait.</p>}
        </div>
      )}

      {errorMessage && <p className="submit__msg">{errorMessage}</p>}
      {returnMessage && (
        <>
          <p className="submit__msg">{returnMessage}</p>
          <p>
            <button
              onClick={() => navigate('/best-schools-awards/dashboard/')}
              type={'button'}
            >
              Return to dashboard
            </button>
          </p>
        </>
      )}
    </>
  )
}

export default PaidSubmission
