import React, { useEffect, useReducer, useState, useContext } from 'react'
import { AwardsInfo, SchoolInfo, SubmissionInfo, UserInfo } from './FormSteps'
import { LoginContext, schoolEntries, singleEntry } from '../../../lib/utils'
import * as FormStyles from './FormStyles.module.scss'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  SaveSchoolAwards,
  ISchoolAwardsSave
} from '../../../graphql/queries/SaveSchoolAwards'
import { Cats } from '../../../lib/data/BestSchoolAwards'
import { useMutation } from '@apollo/client'

export interface FormFields {
  [key: string]: string | boolean
}

const stripePromise = loadStripe(
  'pk_live_51JK2eMFE4F4rvfZHiSNsg303ki072YgFoAZedXIR5RN2jWkevsklIicfSGmT6kUHqQ3FxMPWJYj1kgcrFnoC8QC200zKhoq9p1'
)

// const stripePromise = loadStripe(
//   'pk_test_51JK2eMFE4F4rvfZH3VYkpmq4OAdTcRDS52IQIbGi1pGoMsOps09hEzu1zq2ilwHk3cmpRKZuqsQzCU3xS3vS7oLn00kG196wD5'
// )

interface FormProps {
  schools?: {
    schoolid: number
    name: string
  }[]
}

export interface FieldChange {
  name: string
  value: string | boolean
}

const Form = ({ schools }: FormProps) => {
  const [saveButtonText, setSaveButtonText] = useState<
    'Save' | 'Saved' | 'Saving'
  >('Save')
  const [savedData, setSavedData] = useState<ISchoolAwardsSave>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [savedDataError, setSavedDataError] = useState<string>()
  const splitCategories = ['22903', '22891', '22904', '34707', '34708']
  const { usr } = useContext(LoginContext)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [sectionValid, setSectionValid] = useState<boolean>(false)
  const [validSections, setValidSections] = useState<number[]>([])
  const formReducer = (state: ISchoolAwardsSave, event: FieldChange) => {
    return {
      ...state,
      [event.name]: event.value
    }
  }

  const [formData, setFormData] = useReducer(formReducer, savedData ?? {})
  const [formStep, setFormStep] = useState<number>(1)
  const [formElement, setFormElement] = useState<JSX.Element>()
  const [saveSchoolAwards, { data, loading: saving, error }] =
    useMutation(SaveSchoolAwards)

  const getSavedData = async (id: string) => {
    try {
      await singleEntry(id).then(res => {
        if (res.success && res.data) {
          if (
            res.data[0].emailaddress.toLowerCase() !==
            usr?.viewer?.email?.toLowerCase()
          ) {
            throw new Error(
              `You're logged in as ${usr?.viewer?.email}, but the entry you're trying to edit belongs to ${res.data[0].emailaddress}`
            )
          }

          setSavedData(res.data[0])
          return true
        } else if (res.message) {
          throw new Error(res.message)
        } else {
          throw new Error('Failed to get saved data')
        }
      })
    } catch (e: any) {
      console.log(e)
      setSavedDataError(e.message)
    }
  }

  useEffect(() => {
    if (savedData) {
      setFormData({
        name: 'schoolname',
        value: savedData.schoolname ?? ''
      })
      setFormData({
        name: 'schoolid',
        value: savedData.schoolid ?? ''
      })
      setFormData({
        name: 'schooldepartment',
        value: savedData.schooldepartment ?? ''
      })
      setFormData({
        name: 'category',
        value: `${savedData.category}` ?? ''
      })
      setFormData({
        name: 'categoryname',
        value: savedData.categoryname ?? ''
      })
      setFormData({
        name: 'county',
        value: savedData.county ?? ''
      })
      setFormData({
        name: 'emailaddress',
        value: savedData.emailaddress ?? ''
      })
      setFormData({
        name: 'jobtitle',
        value: savedData.jobtitle ?? ''
      })
      setFormData({
        name: 'name',
        value: savedData.name ?? ''
      })
      setFormData({
        name: 'postcode',
        value: savedData.postcode ?? ''
      })
      setFormData({
        name: 'telephone',
        value: savedData.telephone ?? ''
      })
      setFormData({
        name: 'town',
        value: savedData.town ?? ''
      })
      setFormData({
        name: 'application',
        value: savedData.application ?? ''
      })
      setFormData({
        name: 'logo',
        value: savedData.logo ?? ''
      })
      setFormData({
        name: 'additionalinformation',
        value: savedData.additionalinformation ?? ''
      })
    }
  }, [savedData])

  useEffect(() => {
    if (typeof window !== undefined) {
      // eslint-disable-next-line node/no-unsupported-features/node-builtins
      const paramId = new URLSearchParams(window.location?.search).get('id')
      // eslint-disable-next-line node/no-unsupported-features/node-builtins
      const catId = new URLSearchParams(window.location?.search).get('c')
      if (paramId) {
        setFormData({
          name: 'entryid',
          value: paramId
        })
        getSavedData(paramId)
      }

      if (catId) {
        setFormData({
          name: 'category',
          value: catId
        })

        const catName = Cats.filter(cat => cat.ID + '' === catId)[0].title
        setFormData({
          name: 'categoryname',
          value: catName
        })
      }
    }
  }, [])

  useEffect(() => {
    if (usr?.viewer) {
      setLoggedIn(true)
    }
  }, [usr])

  useEffect(() => {
    if (data && data.saveSchoolAwards?.entryid) {
      setSaveButtonText('Saved')
      setFormData({
        name: 'entryid',
        value: data.saveSchoolAwards.entryid
      })
    }
  }, [data, saving, error])

  const saveForm = async () => {
    setSaveButtonText('Saving')
    saveSchoolAwards({
      variables: {
        payload: formData
      }
    })
  }

  const handleChange = (field: FieldChange) => {
    setSaveButtonText('Save')
    setErrorMessage(undefined)
    setFormData({
      name: field.name,
      value: field.value
    })
  }

  useEffect(() => {
    if (validSections.includes(formStep)) {
      setSectionValid(true)
    } else {
      setSectionValid(false)
    }
  }, [validSections, formStep])

  useEffect(() => {
    const validSectionsClone = [...validSections]
    if (formStep === 1) {
      if (formData.schoolname && formData.category) {
        if (splitCategories.includes(formData.category as string)) {
          if (formData.schooldepartment) {
            validSectionsClone.push(1)
            setValidSections(validSectionsClone)
          } else {
            validSectionsClone.splice(validSectionsClone.indexOf(formStep), 1)
            setValidSections(validSectionsClone)
          }
        } else {
          validSectionsClone.push(1)
          setValidSections(validSectionsClone)
        }
      } else {
        validSectionsClone.splice(validSectionsClone.indexOf(formStep), 1)
        setValidSections(validSectionsClone)
      }
    } else if (formStep === 2) {
      const fields = [
        'county',
        'emailaddress',
        'jobtitle',
        'name',
        'postcode',
        'telephone',
        'town'
      ]

      let valid = true
      fields.forEach(field => {
        if (!formData[field as keyof ISchoolAwardsSave]) {
          valid = false
        }
      })
      if (valid) {
        validSectionsClone.push(2)
        setValidSections(validSectionsClone)
      } else {
        validSectionsClone.splice(validSectionsClone.indexOf(formStep), 1)
        setValidSections(validSectionsClone)
      }
    } else if (formStep === 3) {
      const fields = ['application', 'logo', 'additionalinformation']

      let valid = true
      fields.forEach(field => {
        if (!formData[field as keyof ISchoolAwardsSave]) {
          valid = false
        }
      })
      if (valid) {
        validSectionsClone.push(3)
        setValidSections(validSectionsClone)
      } else {
        validSectionsClone.splice(validSectionsClone.indexOf(formStep), 1)
        setValidSections(validSectionsClone)
      }
    }
  }, [formData, formStep])

  useEffect(() => {
    let formEl: JSX.Element
    const fd = formData

    switch (formStep) {
      case 1:
        formEl = (
          <>
            {schools && (
              <SchoolInfo
                schools={schools}
                handleChange={handleChange}
                formData={formData}
              />
            )}
          </>
        )
        break

      case 3:
        formEl = <AwardsInfo handleChange={handleChange} formData={fd} />
        break

      case 2:
        formEl = <UserInfo handleChange={handleChange} formData={fd} />
        break

      case 4:
        formEl = <SubmissionInfo handleChange={saveForm} formData={fd} />
        break

      default:
        formEl = <UserInfo handleChange={handleChange} formData={fd} />
        break
    }

    setFormElement(formEl)
  }, [formStep, formData, validSections])

  const checkSchoolCanEnter = async () => {
    try {
      if (formData.entryid) {
        setFormStep(formStep + 1)
        return
      }

      if (formData.schoolid) {
        const schoolEntriesRes = await schoolEntries(
          formData.schoolid as string
        )

        if (schoolEntriesRes.success === true) {
          const entries = schoolEntriesRes.data
          entries.some((entry: any) => {
            if (`${entry.catid}` === `${formData.category}`) {
              throw new Error(`This school has already entered this category.`)
            }
          })
          setFormStep(formStep + 1)
        } else {
          setFormStep(formStep + 1)
        }
      } else {
        throw new Error('No school ID')
      }
    } catch (e: any) {
      setErrorMessage(e.message)
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <div>
        {loggedIn ? (
          <>
            {savedDataError && (
              <>
                <p className={FormStyles.ErrorMsg}>
                  It looks like you&apos;re trying to edit an entry, but there
                  was an error:{` `}
                  <span>{savedDataError}</span>
                </p>
              </>
            )}
            <div className={FormStyles.Form}>{formElement}</div>
            {formStep < 4 && (
              <div className={FormStyles.Buttons}>
                {formStep > 1 && (
                  <>
                    <button
                      style={{ gridColumn: 1 }}
                      onClick={() => setFormStep(formStep - 1)}
                    >
                      Go Back
                    </button>
                    <button
                      style={{ gridColumn: 2 }}
                      onClick={() => saveForm()}
                      disabled={saving}
                    >
                      {saveButtonText}
                    </button>
                  </>
                )}
                {sectionValid && (
                  <>
                    {formStep === 1 ? (
                      <button
                        style={{ gridColumn: 3 }}
                        onClick={() => checkSchoolCanEnter()}
                        disabled={errorMessage ? true : false}
                      >
                        {errorMessage ? 'Error' : 'Continue'}
                      </button>
                    ) : (
                      <button
                        style={{ gridColumn: 3 }}
                        onClick={() => setFormStep(formStep + 1)}
                      >
                        Continue
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            <div className={FormStyles.Error}>
              {errorMessage && <p>{errorMessage}</p>}
            </div>
          </>
        ) : (
          <div>
            <p>
              You must <a href="/best-schools-awards/dashboard/login">log in</a>{' '}
              to enter.
            </p>
          </div>
        )}
      </div>
    </Elements>
  )
}

export default Form
