import React, { useState, useEffect } from 'react'
import { FormFields } from '../Form'
import { schoolAwardsCheckSubmittedEntries } from '../../../../lib/utils'
import PaidSubmission from '../FormHelpers/PaidSubmission'
import FreeSubmission from '../FormHelpers/FreeSubmission'
import LoadingLogomark from '../../../../components/LoadingLogomark/LoadingLogomark'

interface Props {
  handleChange: () => Promise<void>
  formData: FormFields
}

const SubmissionInfo = ({ handleChange, formData }: Props) => {
  const [isFree, setIsFree] = useState<boolean>(false)
  const formdata = formData
  const [checkedEntries, setCheckedEntries] = useState<boolean>(false)
  const [entryId, setEntryId] = useState<number>(
    parseInt(
      formData.entryid &&
        (typeof formData.entryid === 'string' ||
          typeof formData.entryid === 'number')
        ? formData.entryid
        : '0',
      10
    )
  )
  const [showLoading, setShowLoading] = useState<boolean>(false)

  useEffect(() => {
    if (
      (formData.entryid && typeof formData.entryid === 'string') ||
      typeof formData.entryid === 'number'
    ) {
      setEntryId(parseInt(formData.entryid, 10))
    }
  }, [formData])

  useEffect(() => {
    if (entryId === 0) {
      setShowLoading(true)
      handleChange()
    }

    schoolAwardsCheckSubmittedEntries(formdata).then(result => {
      setCheckedEntries(true)
      if (result.success === false) {
        setIsFree(true)
      }
    })
  }, [])

  return (
    <fieldset>
      <legend>Ready to submit?</legend>
      <div style={{ textAlign: 'center' }}>
        <LoadingLogomark
          show={showLoading}
          complete={entryId && checkedEntries ? true : false}
          size="88px"
          persist={true}
        />
      </div>
      {entryId && checkedEntries ? (
        <>
          {isFree ? (
            <FreeSubmission formData={formdata} />
          ) : (
            <PaidSubmission formData={formdata} />
          )}
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>
            <p>Preparing your entry for submission. Please wait.</p>
          </div>
        </>
      )}
    </fieldset>
  )
}

export default SubmissionInfo
