import React, { useContext, useEffect, useState } from 'react'
import { SchoolDetails } from '../../../types'
import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapper'
import * as DashboardStyles from './Dashboard.module.scss'
import { Helmet } from 'react-helmet'
import Form from '../../../components/BestSchoolsAwards/Form/Form'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../../lib/utils'

const BSAEnter = () => {
  const [schools, setSchools] = useState<SchoolDetails[]>()
  const SchoolContext = useContext(SchoolAwardsContext)

  useEffect(() => {
    fetch('/.netlify/functions/getSchools')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then(data => {
        const relevantData = data.slice(1)
        const mappedData: SchoolDetails[] = relevantData.map((row: any) => ({
          schoolid: parseInt(row[0], 10),
          name: row[1]
        }))
        setSchools(mappedData)
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
      .finally(() => {
        console.log('finished')
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>
          Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          {SchoolContext === SchoolAwardsStates.OPEN ? (
            <>{schools && <Form schools={schools} />}</>
          ) : (
            <>
              <h3>Sorry, the portal is closed for new entries.</h3>
              <p>
                If you’re in touch with the administration team, and need to
                submit additional information, please contact us{' '}
                <a href="mailto:hq@muddystilettos.co.uk">here</a>.
              </p>
              <p>Good luck!</p>
            </>
          )}
        </div>
      </DashboardWrapper>
    </>
  )
}

export default BSAEnter
