import React, { useEffect, useRef, useState } from 'react'
import { FieldChange, FormFields } from '../Form'
import { Cats } from '../../../../lib/data/BestSchoolAwards'
import * as FormStyles from '../FormStyles.module.scss'
import { Link } from 'gatsby'

interface ISchool {
  schoolid: number
  name: string
}

interface Props {
  handleChange: (field: FieldChange) => void
  formData: FormFields
  schools: ISchool[]
}

const SchoolInfo = ({ handleChange, formData, schools }: Props) => {
  const schoolRef = useRef<HTMLInputElement>(null)
  const splitCategories = ['22903', '22891', '22904', '34707', '34708']
  const [suggestions, setSuggestions] = useState<ISchool[]>([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [showSchoolType, setShowSchoolType] = useState(false)
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange({
      name: e.target.name,
      value: e.target.value
    })
  }

  const handleSelectCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange({
      name: e.target.name,
      value: e.target.value
    })

    const catName = Cats.filter(cat => cat.ID + '' === e.target.value)[0].title

    handleChange({
      name: 'categoryname',
      value: catName
    })

    if (e.target.name === 'category') {
      if (splitCategories.includes(e.target.value)) {
        setShowSchoolType(true)
      } else {
        setShowSchoolType(false)
      }
    }
  }

  const handleSchoolNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const suggestions = schools.filter(school =>
      school.name.toLowerCase().includes(value.toLowerCase())
    )

    setSuggestions(suggestions?.slice(0, 5) ?? [])
    setShowSuggestions(true)
  }

  const selectSchoolName = (school: ISchool) => {
    handleChange({
      name: 'schoolname',
      value: school.name
    })
    handleChange({
      name: 'schoolid',
      value: '' + school.schoolid
    })
    setShowSuggestions(false)
    if (schoolRef.current) schoolRef.current.value = school.name
  }

  useEffect(() => {
    if (formData.schoolid) {
      const school = schools.filter(
        school => school.schoolid === parseInt(`${formData.schoolid}`, 10)
      )[0]
      if (school) {
        handleChange({
          name: 'schoolname',
          value: school.name
        })
        if (schoolRef.current) schoolRef.current.value = school.name
      }
    }
  }, [formData.schoolid])

  return (
    <div>
      <fieldset>
        <legend>School Info</legend>
        <p>
          <label htmlFor="schoolname">School Name</label>
          <input
            ref={schoolRef}
            type="text"
            name="schoolname"
            onChange={e => handleSchoolNameChange(e)}
            defaultValue={(formData.schoolName as string) || ''}
          />
        </p>
        {showSuggestions && (
          <div className={FormStyles.SuggestionsWrapper}>
            {suggestions.length > 0 ? (
              <>
                <div className={FormStyles.SchoolSuggestions}>
                  {suggestions.map(suggestion => (
                    <div
                      key={suggestion.schoolid}
                      className={FormStyles.IsSuggestion}
                      onClick={() => selectSchoolName(suggestion)}
                    >
                      {suggestion.name}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <span className={FormStyles.SchoolSuggestions}>
                  <span className={FormStyles.NoSuggestions}>
                    <div>
                      Can&apos;t find your school? Check out our{' '}
                      <Link to={`/best-schools-awards/terms-conditions`}>
                        terms
                      </Link>{' '}
                      and then{' '}
                      <Link to={'mailto:hq@muddystilettos.co.uk'}>
                        email us
                      </Link>{' '}
                      to let us know!
                    </div>
                  </span>
                </span>
              </>
            )}
          </div>
        )}
        <p>
          <label htmlFor="category">
            Select the category you wish to enter
          </label>
          <select
            name="category"
            value={(formData.category as string) || ''}
            onChange={e => handleSelectCategory(e)}
          >
            <option value="">Select a category</option>
            {Cats.map(cat => (
              <option key={cat.ID} value={cat.ID}>
                {cat.title}
              </option>
            ))}
          </select>
        </p>
        {showSchoolType && (
          <p>
            <label htmlFor="schooldepartment">
              Select the school department entering
            </label>
            <select
              name="schooldepartment"
              id="schooldepartment"
              onChange={e => handleSelectChange(e)}
              defaultValue={(formData?.schooldepartment as string) || ''}
            >
              <option value="" disabled></option>
              <option value="prep">Prep</option>
              <option value="senior">Senior</option>
            </select>
          </p>
        )}
      </fieldset>
    </div>
  )
}

export default SchoolInfo
