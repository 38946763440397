import React, { useState } from 'react'
import { FormFields } from '../Form'
import { makePayment } from '../../../../lib/utils'
import * as FormStyles from '../FormStyles.module.scss'
import { navigate } from 'gatsby'
import LoadingLogomark from '../../../../components/LoadingLogomark/LoadingLogomark'

interface Props {
  formData: FormFields
}

const FreeSubmission = ({ formData }: Props) => {
  const paymentIntent = 'n/a'
  const amount = 0
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [returnMessage, setReturnMessage] = useState<string>()

  const handleSubmission = async () => {
    setSubmitting(true)
    try {
      const response = await makePayment({
        entryId: `${formData.entryid}`,
        formData,
        amount,
        paymentIntent,
        paymentId: 'n/a'
      })
      setSubmitting(false)
      if (response && response.success === 1) {
        setReturnMessage('Thank you. Your entry has now been submitted.')
      } else {
        setErrorMessage(response.message)
        throw new Error(response.message)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="payment">
        {!returnMessage && (
          <>
            <p className={FormStyles.SubmitCTA}>
              You are entering&nbsp;<strong>{formData.schoolname}</strong>
              &nbsp;into the&nbsp;
              <strong>{formData.categoryname}</strong>&nbsp;category. Once you
              submit your entry you will not be able to edit it.
            </p>
            <p>
              <button
                type="button"
                disabled={returnMessage !== undefined || submitting}
                onClick={() => handleSubmission()}
              >
                {submitting && 'Submitting'}
                {!submitting && !returnMessage && <>Submit your entry</>}
              </button>
            </p>
          </>
        )}

        {(submitting ||
          returnMessage !== undefined ||
          errorMessage !== undefined) && (
          <div style={{ textAlign: 'center' }}>
            <LoadingLogomark
              show={true}
              complete={
                !submitting &&
                (returnMessage !== undefined || errorMessage !== undefined)
                  ? true
                  : false
              }
              size="88px"
            />
            {submitting && <p>Submitting your entry. Please wait.</p>}
          </div>
        )}

        {errorMessage && <p className="submit__msg">{errorMessage}</p>}
        {returnMessage && (
          <>
            <p className="submit__msg">{returnMessage}</p>
            <p>
              <button
                onClick={() => navigate('/best-schools-awards/dashboard/')}
                type={'button'}
              >
                Return to dashboard
              </button>
            </p>
          </>
        )}
      </div>
    </>
  )
}

export default FreeSubmission
