import React, { useContext, useEffect } from 'react'

import { FieldChange, FormFields } from '../Form'
import { LoginContext } from '../../../../lib/utils'

interface Props {
  handleChange: (field: FieldChange) => void
  formData: FormFields
}

const UserInfo = ({ handleChange, formData }: Props) => {
  const { usr } = useContext(LoginContext)
  const formdata = formData
  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    handleChange({
      name: e.target.name,
      value: e.target.value
    })
  }

  useEffect(() => {
    usr &&
      usr.viewer.email &&
      handleChange({
        name: 'emailaddress',
        value: usr.viewer.email
      })
  }, [])
  return (
    <div>
      <fieldset>
        <legend>
          Your details
          <span className="entry__barometer"></span>
        </legend>
        <p>
          <label htmlFor="name">Your name</label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleFormChange}
            value={(formdata.name as string) || ''}
          />
        </p>
        <p>
          <label htmlFor="emailaddress">Email address</label>
          <input
            type="emailaddress"
            name="emailaddress"
            id="emailaddress"
            onChange={handleFormChange}
            value={(formdata.emailaddress as string) || usr?.viewer.email}
          />
          {formdata.emailaddress &&
            usr?.viewer.email !== formdata.emailaddress && (
              <span>
                Please note: Using an email address not associated with your
                account will mean it does not appear in your &apos;Entries&apos;
                list.
              </span>
            )}
        </p>
        <p>
          <label htmlFor="jobtitle">Your Job Title</label>
          <input
            type="text"
            name="jobtitle"
            id="jobtitle"
            onChange={handleFormChange}
            value={(formdata.jobtitle as string) || ''}
          />
        </p>
        <p>
          <label htmlFor="town">School Village/Town/City</label>
          <input
            type="text"
            name="town"
            id="town"
            onChange={handleFormChange}
            value={(formdata.town as string) || ''}
          />
        </p>
        <p>
          <label htmlFor="county">School County/Region</label>
          <select
            name="county"
            id="county"
            onChange={handleFormChange}
            value={(formdata.county as string) || ''}
          >
            <option value="">Select county or region</option>
            <option value="Wales">Wales</option>
            <option value="Scotland">Scotland</option>
            <option value="Northern Ireland">Northern Ireland</option>
            <option value="Channel Islands">Channel Islands</option>
            <option value="Bedfordshire">Bedfordshire</option>
            <option value="Berkshire">Berkshire</option>
            <option value="Bristol">Bristol</option>
            <option value="Buckinghamshire">Buckinghamshire</option>
            <option value="Cambridgeshire">Cambridgeshire</option>
            <option value="Cheshire">Cheshire</option>
            <option value="City of London">City of London</option>
            <option value="Cornwall">Cornwall</option>
            <option value="Cumbria">Cumbria</option>
            <option value="Derbyshire">Derbyshire</option>
            <option value="Devon">Devon</option>
            <option value="Dorset">Dorset</option>
            <option value="Durham">Durham</option>
            <option value="East Riding of Yorkshire">
              East Riding of Yorkshire
            </option>
            <option value="East Sussex">East Sussex</option>
            <option value="Essex">Essex</option>
            <option value="Gloucestershire">Gloucestershire</option>
            <option value="Greater London">Greater London</option>
            <option value="Greater Manchester">Greater Manchester</option>
            <option value="Hampshire">Hampshire</option>
            <option value="Herefordshire">Herefordshire</option>
            <option value="Hertfordshire">Hertfordshire</option>
            <option value="Isle of Wight">Isle of Wight</option>
            <option value="Kent">Kent</option>
            <option value="Lancashire">Lancashire</option>
            <option value="Leicestershire">Leicestershire</option>
            <option value="Lincolnshire">Lincolnshire</option>
            <option value="Merseyside">Merseyside</option>
            <option value="Norfolk">Norfolk</option>
            <option value="North Yorkshire">North Yorkshire</option>
            <option value="Northamptonshire">Northamptonshire</option>
            <option value="Northumberland">Northumberland</option>
            <option value="Nottinghamshire">Nottinghamshire</option>
            <option value="Oxfordshire">Oxfordshire</option>
            <option value="Rutland">Rutland</option>
            <option value="Shropshire">Shropshire</option>
            <option value="Somerset">Somerset</option>
            <option value="South Yorkshire">South Yorkshire</option>
            <option value="Staffordshire">Staffordshire</option>
            <option value="Suffolk">Suffolk</option>
            <option value="Surrey">Surrey</option>
            <option value="Tyne and Wear">Tyne and Wear</option>
            <option value="Wales">Wales</option>
            <option value="Warwickshire">Warwickshire</option>
            <option value="West Midlands">West Midlands</option>
            <option value="West Sussex">West Sussex</option>
            <option value="West Yorkshire">West Yorkshire</option>
            <option value="Wiltshire">Wiltshire</option>
            <option value="Worcestershire">Worcestershire</option>
            <option value="Other">Other</option>
          </select>
        </p>
        <p>
          <label htmlFor="postcode">School Postcode</label>
          <input
            type="text"
            name="postcode"
            id="postcode"
            onChange={handleFormChange}
            value={(formdata.postcode as string) || ''}
          />
        </p>
        <p>
          <label htmlFor="telephone">School Telephone Number</label>
          <input
            type="text"
            name="telephone"
            id="telephone"
            onChange={handleFormChange}
            value={(formdata.telephone as string) || ''}
          />
        </p>
      </fieldset>
    </div>
  )
}

export default UserInfo
